<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="less">
#app {
  height: 100%;
  background-color: #f6f8ff;
}
</style>
